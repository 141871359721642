<script setup>
import { computed, toRef, ref, onMounted, onBeforeUnmount } from 'vue';
import { useMainStore } from '@/stores/main';
import ControlIcon from '@/components/ControlIcon.vue';
import { useField } from 'vee-validate';

const props = defineProps({
  name: {
    type: String,
    default: null,
  },
  id: {
    type: String,
    default: null,
  },
  autocomplete: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  icon: {
    type: String,
    default: null,
  },
  options: {
    type: Array,
    default: null,
  },
  type: {
    type: String,
    default: 'text',
  },
  class: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [String, Number, Boolean, Array, Object],
    default: '',
  },
  successMessage: {
    type: String,
    default: '',
  },
  required: Boolean,
  borderless: Boolean,
  transparent: Boolean,
  ctrlKFocus: Boolean,
  disabled: Boolean,
});

const emit = defineEmits(['update:modelValue', 'right-icon-click']);

const computedValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});

const inputElClass = computed(() => {
  const base = [
    'px-3 py-2 max-w-full focus:ring-0 focus:outline-none border-gray-400 rounded w-full placeholder-gray-400',
    'dark:placeholder-gray-400',
    computedType.value === 'textarea' ? 'h-24' : 'h-10',
    props.borderless ? 'border-0' : 'border',
    props.transparent ? 'bg-transparent' : 'bg-white dark:bg-gray-800',
    props.disabled ? 'bg-gray-100' : '',
    props.class,
  ];

  if (props.icon) {
    base.push('pl-10');
  }

  return base;
});

const computedType = computed(() => (props.options ? 'select' : props.type));

const controlIconH = computed(() => (props.type === 'textarea' ? 'h-full' : 'h-10'));

const mainStore = useMainStore();

const inputEl = ref(null);

if (props.ctrlKFocus) {
  const fieldFocusHook = (e) => {
    if (e.ctrlKey && e.key === 'k') {
      e.preventDefault();
      inputEl.value.focus();
    } else if (e.key === 'Escape') {
      inputEl.value.blur();
    }
  };

  onMounted(() => {
    if (!mainStore.isFieldFocusRegistered) {
      window.addEventListener('keydown', fieldFocusHook);
      mainStore.isFieldFocusRegistered = true;
    } else {
      // console.error('Duplicate field focus event')
    }
  });

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', fieldFocusHook);
    mainStore.isFieldFocusRegistered = false;
  });
}

const name = toRef(props, 'name');

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.modelValue,
});
</script>

<template>
  <div class="relative">
    <select
      v-if="computedType === 'select'"
      :id="id"
      v-model="computedValue"
      :name="name"
      :class="inputElClass"
      :disabled="disabled"
    >
      <option value="" v-if="props.placeholder">
        {{ props.placeholder }}
      </option>
      <option v-for="option in options" :key="option.id ?? option" :value="option.value">
        {{ option.label ?? option }}
      </option>
    </select>
    <textarea
      v-else-if="computedType === 'textarea'"
      :id="id"
      v-model="computedValue"
      :class="inputElClass"
      :name="name"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      @input="handleChange"
      @blur="handleBlur"
    />
    <input
      v-else
      :id="id"
      ref="inputEl"
      v-model="computedValue"
      :name="name"
      :autocomplete="autocomplete"
      :required="required"
      :placeholder="placeholder"
      :type="computedType"
      :class="inputElClass"
      :disabled="disabled"
      @input="handleChange"
      @blur="handleBlur"
    />
    <control-icon v-if="icon" :icon="icon" :h="controlIconH" />
    <p class="text-orange-600 help-message" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

import { defineStore } from 'pinia';
import * as styles from '@/styles';
import { darkModeKey, styleKey } from '@/config';
import axios from 'axios';

export const useMainStore = defineStore('main', {
  state: () => ({
    /* Styles */
    lightBorderStyle: '',
    lightBgStyle: '',
    asideStyle: '',
    asideBrandStyle: '',
    asideMenuCloseLgStyle: '',
    asideMenuLabelStyle: '',
    asideMenuItemStyle: '',
    asideMenuItemActiveStyle: '',
    asideMenuItemInactiveStyle: '',
    asideSubmenuListStyle: '',
    navBarItemLabelStyle: '',
    navBarItemLabelHoverStyle: '',
    navBarItemLabelActiveColorStyle: '',
    navBarMenuListUpperLabelStyle: '',
    tableTrStyle: '',
    tableTrOddStyle: '',
    overlayStyle: '',

    /* User */
    userId: null,
    userName: null,
    userEmail: null,
    userAvatar: null,

    /* fullScreen - fullscreen form layout (e.g. login page) */
    isFullScreen: true,

    /* Aside */
    isAsideMobileExpanded: false,
    isAsideLgActive: false,

    /* Dark mode */
    darkMode: false,

    /* Field focus with ctrl+k (to register only once) */
    isFieldFocusRegistered: false,

    /* Sample data (commonly used) */
    clients: [],
    history: [],
    profile: {},
    credit: null,
    order: null,
  }),
  actions: {
    setUser(payload) {
      if (payload.id) {
        this.userId = payload.id;
      }
      if (payload.name) {
        this.userName = payload.name;
      }
      if (payload.email) {
        this.userEmail = payload.email;
      }
      if (payload.avatar) {
        this.userAvatar = payload.avatar;
      }
    },

    async updateUser(payload) {
      console.log(payload);
      return await axios({
        url: '/api/auth/setting',
        method: 'put',
        baseURL: '/',
        data: payload,
      }).then((res) => {
        this.userEmail = res.data.data.attributes.email;
        return res.data.data;
      });
    },

    async updatePass(payload) {
      return await axios({
        url: '/api/user/account/update_password',
        method: 'put',
        baseURL: '/',
        data: payload,
      }).then((res) => {
        // console.log(res);
        return res.data.data;
        // localStorage.setItem
        // this.userEmail = res.data.data.attributes.email;
      });
    },

    setStyle(payload) {
      const style = styles[payload] ?? styles.basic;

      document.body.className = style.body;
      document.documentElement.className = style.html;

      if (localStorage[styleKey] !== payload) {
        localStorage.setItem(styleKey, payload);
      }

      for (const key in style) {
        if (['body', 'html'].includes(key)) {
          continue;
        }

        this[`${key}Style`] = style[key];
      }
    },

    asideMobileToggle(payload = null) {
      const isShow = payload !== null ? payload : !this.isAsideMobileExpanded;

      document.getElementById('app').classList[isShow ? 'add' : 'remove']('ml-60', 'lg:ml-0');

      document.documentElement.classList[isShow ? 'add' : 'remove']('m-clipped');

      this.isAsideMobileExpanded = isShow;
    },

    asideLgToggle(payload = null) {
      this.isAsideLgActive = payload !== null ? payload : !this.isAsideLgActive;
    },

    fullScreenToggle(payload) {
      this.isFullScreen = payload;

      document.documentElement.classList[payload ? 'add' : 'remove']('full-screen');
    },

    setDarkMode(payload = null) {
      const value = payload !== null ? payload : !this.darkMode;

      document.documentElement.classList[value ? 'add' : 'remove']('dark');

      localStorage.setItem(darkModeKey, value ? '1' : '0');

      this.darkMode = value;
    },

    fetch(sampleDataKey) {
      axios
        .get(`data/${sampleDataKey}.json`)
        .then((r) => {
          if (r.data && r.data.data) {
            this[sampleDataKey] = r.data.data;
          }
        })
        .catch((error) => {
          // alert(error.message);
        });
    },
    getObject(objectKey) {
      axios
        .get(`${objectKey}.json`)
        .then((r) => {
          if (r.data && r.data.data) {
            this[objectKey] = r.data.data.attributes;
          }
        })
        .catch((error) => {
          // alert(error.message);
        });
    },
    setProfile() {
      axios
        .get('/profile')
        .then((r) => {
          if (r.data && r.data.data) {
            this.profile = r.data.data.attributes;
            this.userId = r.data.data.id;
            this.userName = r.data.data.attributes.username;
            this.userEmail = r.data.data.attributes.email;
            this.userAvatar =
              'https://avatars.dicebear.com/api/avataaars/example.svg?options[top][]=shortHair&options[accessoriesChance]=93';
          }
        })
        .catch((error) => {
          // alert(error.message);
        });
    },
    setCredit() {
      axios
        .get('credit')
        .then((res) => {
          if (res) {
            this.credit = parseFloat(res.data).toFixed(2);
          }
        })
        .catch((error) => {
          // alert(error.message);
        });
    },
    async getCredit() {
      await axios
        .get('credit')
        .then((res) => {
          if (res) {
            // console.log(res);
            // console.log(res.data);
            return res;
          }
        })
        .catch((error) => {
          // alert(error.message);
        });
    },
    setOrder() {
      axios
        .get('order')
        .then((res) => {
          if (res) {
            // console.log(res.data);
            this.order = res.data;
          }
        })
        .catch((error) => {
          // alert(error.message);
        });
    },
  },
});
